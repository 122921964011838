import { DOCUMENT } from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import { APP_INITIALIZER, Provider } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withRouterConfig } from '@angular/router';
import { provideHotToastConfig } from '@ngneat/hot-toast';
import { MarkedRenderer, MARKED_OPTIONS, provideMarkdown } from 'ngx-markdown';
import { BUILD_TIME, ReachVersion, REACH_VERSION } from '~/core/tokens';
import { rootRoutes } from '~/root/root.router';
import { environment } from '~env';
import { provideChatConfiguration } from '~ng-common-lib/chats/tokens/configuration';
import {
	CommonEnvironmentName,
	provideCommonEnvironment,
} from '~ng-common-lib/core/tokens/environment';

declare const PACKAGE_VERSION: ReachVersion;
declare const COMMIT_HASH: string;
declare const WEBPACK_BUILD_TIME: number;

const getVersion = () => {
	// If we have hash and is not production, show commit hash
	if (!!COMMIT_HASH && environment.environmentName !== CommonEnvironmentName.PRODUCTION) {
		return `${PACKAGE_VERSION}.${COMMIT_HASH}`;
	}

	return `${PACKAGE_VERSION}`;
};

const provideClientTheme = (): Provider => {
	return {
		provide: APP_INITIALIZER,
		useFactory: (document: Document) => {
			return () => {
				const rootElement = document.documentElement;

				const setClientTheme = () => {
					let darkMode = false;
					if (
						window.matchMedia &&
						window.matchMedia('(prefers-color-scheme: dark)').matches
					) {
						darkMode = true;
					}

					rootElement.setAttribute('client-theme', darkMode ? 'dark' : 'light');
				};

				setClientTheme();

				window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
					setClientTheme();
				});
			};
		},
		multi: true,
		deps: [DOCUMENT],
	};
};

export const getRootProviders = () => {
	const appVersion = getVersion();

	const properties = {
		packageVersion: PACKAGE_VERSION,
		commitHash: COMMIT_HASH,
		buildTime: WEBPACK_BUILD_TIME,
		envName: environment.environmentName,
		reachVersion: appVersion,
	};

	if (environment.environmentName !== CommonEnvironmentName.PRODUCTION) {
		console.log(properties);
		console.log(environment);
	}

	return [
		provideRouter(rootRoutes, withRouterConfig({ paramsInheritanceStrategy: 'always' })),
		provideClientTheme(),
		provideAnimations(),
		provideHttpClient(),
		provideMarkdown({
			markedOptions: {
				provide: MARKED_OPTIONS,
				useFactory: () => {
					const renderer = new MarkedRenderer();
					renderer.link = (config) => {
						return `<a href="${config.href}" target="_blank">${config.text}</a>`;
					};
					return {
						renderer,
					};
				},
			},
		}),
		provideHotToastConfig(),
		provideCommonEnvironment(environment),
		{ provide: BUILD_TIME, useValue: BUILD_TIME },
		{ provide: REACH_VERSION, useValue: appVersion },
		provideChatConfiguration({
			showOptions: false,
			canInteract: false,
		}),
	];
};
