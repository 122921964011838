import { InjectionToken, Provider } from '@angular/core';

export enum CommonEnvironmentName {
	TEST = 'TEST',
	PRODUCTION = 'PRODUCTION',
	REPLICA = 'REPLICA',
	STAGING = 'STAGING',
	DEVELOPMENT = 'DEVELOPMENT',
	LOCAL = 'LOCAL',
}

export interface CommonEnvironment {
	environmentName: CommonEnvironmentName;

	/** server is `api.rea.ch` */
	server: {
		url: string;
	};

	/** mainClient is `mobile.rea.ch` */
	mainClient: {
		url: string;
	};

	/** handbook is `handbook.rea.ch` */
	handbook: {
		url: string;
	};

	myGenieServer: {
		url: string;
	};
}

export const COMMON_ENVIRONMENT = new InjectionToken<CommonEnvironment>('COMMON_ENVIRONMENT');

export const provideCommonEnvironment = (env: CommonEnvironment): Provider => {
	return {
		provide: COMMON_ENVIRONMENT,
		useValue: env,
	};
};
