import { inject, Injectable } from '@angular/core';
import { COMMON_ENVIRONMENT } from '~ng-common-lib/core/tokens/environment';
import { ApiHttpClientService } from './http.service';
import { cleanUrl, flat, splitPathSegment, urlFromSegments } from './utils';

@Injectable({ providedIn: 'root' })
export abstract class BaseApiService {
	/**
	 * Url of the server, the constructor stores it cleanly (without extra slashes).
	 */
	protected cacheTimer = performance.now();

	protected abstract readonly commonPath: string[];

	protected get paramTime(): string {
		return `${Date.now()}`.substring(2);
	}

	protected get withoutCacheParam(): string {
		return this.paramTime;
	}

	/**
	 * @param path Path to add to the `baseUrl` and `commonPath`.
	 * @returns The `baseUrl`, `commonPath` and the `path` properly joined.
	 */
	protected url = (...path: (string | number)[]): string => {
		const urlSegments = flat((path || []).map(splitPathSegment));
		const commonPath = cleanUrl(urlFromSegments(flat(this.commonPath.map(splitPathSegment))));
		const cleanCommonPath = commonPath ? `${commonPath}/` : '';
		const cleanSegments = cleanUrl(urlFromSegments(urlSegments));
		return `${this.baseUrl}/${cleanCommonPath}${cleanSegments}`;
	};

	public cleanCache(): void {
		this.cacheTimer = performance.now();
	}

	protected readonly http = inject(ApiHttpClientService);
	protected readonly environment = inject(COMMON_ENVIRONMENT);
	protected readonly baseUrl = cleanUrl(this.environment.server.url);
}
