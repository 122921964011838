import { CommonEnvironment, CommonEnvironmentName } from '~ng-common-lib/core/tokens/environment';

export const environment: CommonEnvironment = {
	environmentName: CommonEnvironmentName.TEST,

	server: {
		url: 'https://api.check.rea.ch',
	},

	mainClient: {
		url: 'https://mobile.check.rea.ch',
	},

	handbook: {
		url: 'https://handbook.dev.rea.ch',
	},

	myGenieServer: {
		url: 'https://chat.check.rea.ch',
	},
};
