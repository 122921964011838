import { inject, InjectionToken, Provider } from '@angular/core';

export type ChatConfiguration = {
	showOptions: boolean;
	canInteract: boolean;
};

export const defaultChatConfiguration: ChatConfiguration = {
	showOptions: true,
	canInteract: true,
};

export const CHAT_CONFIGURATION = new InjectionToken<ChatConfiguration>('CHAT_NODE_ROUTER_LINK', {
	factory: () => defaultChatConfiguration,
});

export const provideChatConfiguration = (config: Partial<ChatConfiguration>): Provider => {
	return {
		provide: CHAT_CONFIGURATION,
		useValue: {
			...defaultChatConfiguration,
			...config,
		},
	};
};

export const injectChatConfiguration = (): ChatConfiguration => {
	return inject(CHAT_CONFIGURATION);
};
